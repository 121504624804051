@mixin salespro-manager-dashboard-form-theme($theme) {
	$background: map-get($theme, background);
	$foreground: map-get($theme, foreground);
	$is-dark: map-get($theme, is-dark);

	salespro-manager-dashboard-form {
		.dialog-content-wrapper {
			.content {
				.mat-progress-spinner circle,
				.mat-spinner circle {
					@if ($is-dark) {
						stroke: rgba(255, 255, 255, 0.5);
					} @else {
						stroke: rgba(0, 0, 0, 0.5);
					}
				}
				.customer-notes {
					.note {
						.title {
							@if ($is-dark) {
								color: rgba(255, 255, 255, 0.5);
							} @else {
								color: rgba(0, 0, 0, 0.5);
							}
						}

						.value {
							@if ($is-dark) {
							} @else {
								color: #000000;
							}
						}
					}
				}
			}
		}
	}
}

@mixin salespro-customer-equipment-review-product-form-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  salespro-customer-equipment-review-product-form {
    .equipment-review-product-form-dialog {
      .dialog-content-wrapper {
        .content {
          .product-form {
            .title {
              @if ($is-dark) {
              } @else {
                color: #6E6E6E;
              }
            }

            .mat-form-field-wrapper {
              input {
                @if ($is-dark) {
                } @else {
                  color: #6E6E6E;
                }
              }
            }
          }

          .product-total {
            .title {
              @if ($is-dark) {
              } @else {
                color: #6E6E6E;
              }
            }

            .value {
              @if ($is-dark) {
              } @else {
                color: #6E6E6E;
              }
            }
          }
        }
      }
    }
  }
}

@mixin salespro-admin-dashboard-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  salespro-admin-dashboard {
    .dashboard {
      .header {
        .search-wrapper {
          background: map-get($background, card);

          .search {
            .mat-icon {
              color: map-get($foreground, icon);
            }

            input {
              background: map-get($background, card);
              color: map-get($foreground, text);
            }
          }
        }
      }

      .content {
        overflow: hidden;

        .sidebar {
          .dashboard-sidebar {
            .dashboard-sidebar-card {
              @if ($is-dark) {
                background: map-get($background, card);
              } @else {
                background: #FFFFFF;
              }

              .dashboard-sidebar-header {
                .title {
                  @if ($is-dark) {
                    color: #FFFFFF;
                  } @else {
                    color: #171717;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@mixin salespro-feature-matrix-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $is-dark: map-get($theme, is-dark);
  
    salespro-feature-matrix {
      .users-group {
        .header {
          .search-wrapper {
            background: map-get($background, card);
  
            .search {
              .mat-icon {
                color: map-get($foreground, icon);
              }
  
              input {
                background: map-get($background, card);
                color: map-get($foreground, text);
              }
            }
          }
        }
        .content {
          overflow: hidden;
  
          .users-group-list {
            .mat-cell {
              @if ($is-dark) {
              } @else {
                color: #656565;
              }
            }
  
            .mat-header-cell {
              @if ($is-dark) {
              } @else {
                color: #959595;
              }
            }
          }
        }
      }
    }
  }
  
@mixin salespro-google-address-selector-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $is-dark: map-get($theme, is-dark);

    .address-verify-dialog {
        .mat-dialog-container {
            padding: 0 !important;
            height: 390px;
            width: 561px;

            .mat-toolbar {
                flex: 1 0 auto;
                min-height: initial;
            }

            .toolbar-bottom {
                height: auto;
            }
        }
        .dialog-content-wrapper {
            max-height: 85vh;
            display: flex;
            flex-direction: column;
            .header {
                background-color: #6bc946;

                .header-title {
                    height: 76px;

                    .dialog-title {
                        color: #ffffff;
                        font-size: 2rem;
                        font-weight: 400;
                    }

                    .close-button {
                        color: #ffffff;
                        cursor: pointer;
                    }
                }
            }
            .input-search {
                width: 100%;
                height: 46px;
            }
            .content {
                display: flex;
                flex-direction: column;
                padding: 4rem 3rem 0;
                .address-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    margin-top: 1.5rem;
                    .user-address {
                        width: 150px;
                    }
                }
                .btn-container {
                    margin-top: 12px; 
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    @include media-breakpoint('lt-sm') {
                        display: block !important; 
                        .view-button-green {   
                            height: auto !important;
                            width: 100 !important;
                            line-height: 1px !important;
                            padding: 8px !important;
                            margin-bottom: 4px!important;
                        }
                        .view-button {   
                            height: auto !important;
                            width: 100 !important;
                            line-height: 1px !important;
                            padding: 8px !important;
                            margin-bottom: 4px!important;
                        }
                    }
                    .view-button {
                        background: darkgrey;
                        color: #ffffff;
                        font-size: 1.6rem;
                        letter-spacing: -0.025em;
                        line-height: 3.6rem;
                        margin: 27px 20px 20px auto;
                        padding: 0 41px;
                        width: fit-content;
                    }
                    .view-button-green {
                        background: #5aa700;
                        color: #ffffff;
                        font-size: 1.6rem;
                        letter-spacing: -0.025em;
                        line-height: 3.6rem;
                        margin: 27px 20px 20px auto;
                        padding: 0 41px;
                        width: fit-content;
                    }
                   
                }
            }
            .search {
                border: 1px solid #e0e0e0;
            }
            .icon-search {
                margin-left: 10px;
            }
            .leadtype {
                width: 460px;
                @include media-breakpoint('lt-md') {
                    width: 330px !important;
                }
                @include media-breakpoint('lt-sm') {
                    width: 200px !important;
                }
            }

            .footer {}
            .notify-text {
                margin-top: 7px;

                font-size: 14px;
                font-weight: 400;
            }
        }
    }
   
}

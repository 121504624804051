@mixin salespro-shared-fullcalendar-form-theme($theme) {
	$background: map-get($theme, background);
	$foreground: map-get($theme, foreground);
	$is-dark: map-get($theme, is-dark);

	salespro-shared-price-form {
		.price-form-dialog {
			.dialog-content-wrapper {
				.content {
					.adjust-price,
					.rmr {
						.title,
						.value {
							@if ($is-dark) {
							} @else {
								color: #3f3f3f;
							}
						}
					}

					.price-range {
						.base-price,
						.list-price {
							@if ($is-dark) {
							} @else {
								color: #757575;
							}
						}
					}
				}

				.footer {
					.save-button {
						@if ($is-dark) {
						} @else {
							color: #757575;
						}
					}
				}
			}
		}
	}
}

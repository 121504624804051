// -----------------------------------------------------------------------------------------------------
@use '@angular/material' as mat;
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$fuse-white: (
  500: white,
  contrast: (
    500: $dark-primary-text,
  ),
);

$fuse-black: (
  500: black,
  contrast: (
    500: $light-primary-text,
  ),
);

$fuse-navy: (
  50: #ececee,
  100: #c5c6cb,
  200: #9ea1a9,
  300: #7d818c,
  400: #5c616f,
  500: #3c4252,
  600: #353a48,
  700: #2d323e,
  800: #262933,
  900: #1e2129,
  A100: #c5c6cb,
  A200: #9ea1a9,
  A400: #5c616f,
  A700: #2d323e,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
  fuse-white: $fuse-white,
  fuse-black: $fuse-black,
  fuse-navy: $fuse-navy,
);

@include fuse-color-classes($custom_palettes);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Angular Material typography
// line-height must be unitless !!!
$typography: mat.define-legacy-typography-config(
  $font-family: 'Muli, Helvetica Neue, Arial, sans-serif',
  $title: mat.define-typography-level(20px, 32px, 600),
  $body-2: mat.define-typography-level(14px, 24px, 600),
  $button: mat.define-typography-level(14px, 14px, 600),
  $input: mat.define-typography-level(16px, 1.125, 400),
);

// Setup the typography
@include mat.all-legacy-component-typographies($typography);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
@import 'src/app/layout/components/chat-panel/chat-panel.theme';
@import 'src/app/layout/components/navbar/vertical/style-1/style-1.theme';
@import 'src/app/layout/components/toolbar/toolbar.theme';

// Admin Module
//-- Admin Components
@import 'src/app/main/admin/components/dashboard-form/dashboard-form.theme';
//-- Admin Pages
@import 'src/app/main/admin/pages/dashboard/dashboard.theme';
@import 'src/app/main/admin/pages/users/users.theme';
@import 'src/app/main/admin/pages/branches/branches.theme';
@import 'src/app/main/admin/pages/packages/packages.theme';
@import 'src/app/main/admin/pages/products/products.theme';
@import 'src/app/main/admin/pages/history/history.theme';
@import 'src/app/main/admin/pages/product-categories/product-categories.theme';
@import 'src/app/main/admin/pages/sales-statistics/sales-statistics.theme';
@import 'src/app/main/admin/pages/builders/builders.theme';
@import 'src/app/main/admin/pages/associations/association.theme';
@import 'src/app/main/admin/pages/users-group/users-group.theme.scss';
@import 'src/app/main/admin/pages/feature-matrix/feature-matrix.theme.scss';

// Manager Module
//--- Manager Components
@import 'src/app/main/manager/components/dashboard-form/dashboard-form.theme';
//--- Manager Pages
@import 'src/app/main/manager/pages/dashboard/dashboard.theme';

// User Module
@import 'src/app/main/user/components/dashboard-form/dashboard-form.theme';
//--- User Pages
@import 'src/app/main/user/pages/dashboard/dashboard.theme';

// Auth Module
@import 'src/app/main/auth/pages/login/login.theme';

// Customer Module
//--- Customer Components
@import 'src/app/main/customer/components/equipment-review-product-form/equipment-review-product-form.theme';
@import 'src/app/main/customer/components/number-stepper/number-stepper.theme';
//--- Customer Pages
@import 'src/app/main/customer/pages/add/add.theme';
@import 'src/app/main/customer/pages/plan/plan.theme';
@import 'src/app/main/customer/pages/equipment-select/equipment-select.theme';
@import 'src/app/main/customer/pages/equipment-review/equipment-review.theme';
@import 'src/app/main/customer/pages/complete/complete.theme';
@import 'src/app/main/customer/pages/docusign-prefill/docusign-prefill.theme';
@import 'src/app/main/customer/pages/lend-foundry-application-page/lend-foundry-application-page.theme';
@import 'src/app/main/customer/pages/lendfoundry-info/lendfoundry-info-theme.scss';
@import 'src/app/main/customer/pages/add-new/add-new.theme';

//--- Remote lendfoundry pages
@import 'src/app/main/auth/pages/remote-lendfoundry-application-page/remote-lend-foundry-application-page.theme.scss';
@import 'src/app/main/auth/pages/remote-lendfoundry-info/remote-lendfoundry-info-theme.scss';

// Shared Module
@import 'src/app/shared/components/price-form/price-form.theme';
@import 'src/app/shared/components/fullcalendar/fullcalendar.theme';
@import 'src/app/shared/components/confirm-task-schedule/confirm-task-schedule.theme';
@import 'src/app/shared/components/monthly-fee-form/monthly-fee-form.theme';
@import 'src/app/shared/components/site-address-auto-complete/site-address-auto-complete.theme';
@import 'src/app/shared/components/confirm-old-add-flow-modal/confirm-old-add-flow-modal.theme';
@import 'src/app/shared/components/google-address-selector/google-address-selector.theme';
@import 'src/app/shared/components/permission-select-modal/permission-select-modal.theme';
@import 'src/app/shared/components/google-login-modal/google-login-modal.theme';
@import 'src/app/shared/components/loan-cancel-modal/loan-cancel-modal.theme.scss';


// Define a mixin for easier access
@mixin components-theme($theme) {
  // Layout components
  @include chat-panel-theme($theme);
  @include navbar-vertical-style-1-theme($theme);
  @include toolbar-theme($theme);

  // Admin Module
  //--- Admin Components
  @include salespro-admin-dashboard-form-theme($theme);
  //--- Admin Pages
  @include salespro-admin-dashboard-theme($theme);
  @include salespro-admin-users-theme($theme);
  @include salespro-admin-branches-theme($theme);
  @include salespro-admin-packages-theme($theme);
  @include salespro-admin-products-theme($theme);
  @include salespro-admin-history-theme($theme);
  @include salespro-product-categories-theme($theme);
  @include salespro-admin-sales-statistics-theme($theme);
  @include salespro-admin-builders-theme($theme);
  @include salespro-admin-associations-theme($theme);
  @include salespro-admin-users-group-theme($theme);
  @include salespro-feature-matrix-theme($theme);
  // Manager Module
  //--- Manager Components
  @include salespro-manager-dashboard-form-theme($theme);
  //--- Manager Pages
  @include salespro-manager-dashboard-theme($theme);

  // User Module
  //--- User Components
  @include salespro-user-dashboard-form-theme($theme);
  //--- User Pages
  @include salespro-user-dashboard-theme($theme);

  // Auth Module
  @include salespro-auth-login-theme($theme);

  // Customer Module
  //--- Customer Components
  @include salespro-customer-equipment-review-product-form-theme($theme);
  @include salespro-customer-number-stepper-theme($theme);
  //--- Customer Pages
  @include salespro-customer-add-theme($theme);
  @include salespro-customer-plan-theme($theme);
  @include salespro-customer-equipment-select-theme($theme);
  @include salespro-customer-equipment-review-theme($theme);
  @include salespro-customer-complete-theme($theme);
  @include salespro-docusign-prefill-theme($theme);
  @include salespro-lend-foundry-application-theme($theme);
  @include salespro-lend-foundry-info-theme($theme);
  @include salespro-customer-add-new-theme($theme);
  //--- Remote lendfoundry pages
  @include salespro-remote-lend-foundry-application-theme($theme);
  @include salespro-remote-lend-foundry-info-theme($theme);
  // Shared Module
  @include salespro-shared-price-form-theme($theme);
  @include salespro-shared-fullcalendar-form-theme($theme);
  @include salespro-shared-task-schedule-theme($theme);
  @include salespro-monthly-fee-form-theme($theme);
  @include salespro-shared-site-address-auto-theme($theme);
  @include salespro-confirm-old-add-flow-modal-theme($theme);
  @include salespro-google-address-selector-theme($theme);
  @include salespro-permission-selector-theme($theme);
  @include salespro-google-login-modal-theme($theme);
  @include salespro-shared-loan-cancel-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette($fuse-navy);
$default-accent-palette: mat.define-palette(mat.$light-blue-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$theme: mat.define-light-theme(
  (
    color: (
      primary: $default-primary-palette,
      accent: $default-accent-palette,
      warn:  $default-warn-palette
    )
  )
);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {
  // Create an Angular Material theme from the $theme map
  @include mat.all-legacy-component-themes($theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($theme);

  // Apply the theme to the user components
  @include components-theme($theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $default-primary-palette,
    accent: $default-accent-palette,
    warn: $default-warn-palette,
  );

  @include fuse-color-classes($palettes);

  .accent {
    background-color: #6bc946 !important;
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Define a yellow light theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$yellow-light-theme-primary-palette: mat.define-palette($fuse-navy, 600, 400, 700);
$yellow-light-theme-accent-palette: mat.define-palette(mat.$yellow-palette, 600, 400, 700);
$yellow-light-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$yellow-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $yellow-light-theme-primary-palette,
      accent: $yellow-light-theme-accent-palette,
      warn: $yellow-light-theme-warn-palette
    )
  )
);

// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-yellow-light {
  // Generate the Angular Material theme
  @include mat.all-legacy-component-colors($yellow-light-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($yellow-light-theme);

  // Apply the theme to the user components
  @include components-theme($yellow-light-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $yellow-light-theme-primary-palette,
    accent: $yellow-light-theme-accent-palette,
    warn: $yellow-light-theme-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a blue-gray dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$blue-gray-dark-theme-primary-palette: mat.define-palette(mat.$blue-palette);
$blue-gray-dark-theme-accent-palette: mat.define-palette(mat.$blue-gray-palette);
$blue-gray-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$blue-gray-dark-theme: mat.define-dark-theme(
  (
    color:(
      primary:$blue-gray-dark-theme-primary-palette,
      accent: $blue-gray-dark-theme-accent-palette,
      warn:   $blue-gray-dark-theme-warn-palette
    )
  )
);

// Add ".theme-blue-gray-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-blue-gray-dark {
  // Generate the Angular Material theme
  @include mat.all-legacy-component-colors($blue-gray-dark-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($blue-gray-dark-theme);

  // Apply the theme to the user components
  @include components-theme($blue-gray-dark-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $blue-gray-dark-theme-primary-palette,
    accent: $blue-gray-dark-theme-accent-palette,
    warn: $blue-gray-dark-theme-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a pink dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme(
  (
    color:(
      primary: $pink-dark-theme-primary-palette,
      accent: $pink-dark-theme-accent-palette,
      warn: $pink-dark-theme-warn-palette
    )
  )
 
);

// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-pink-dark {
  // Generate the Angular Material theme
  @include mat.all-legacy-component-colors($pink-dark-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($pink-dark-theme);

  // Apply the theme to the user components
  @include components-theme($pink-dark-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
    primary: $pink-dark-theme-primary-palette,
    accent: $pink-dark-theme-accent-palette,
    warn: $pink-dark-theme-warn-palette,
  );

  @include fuse-color-classes($palettes);
}

body.theme-default .mat-form-field-appearance-fill .mat-form-field-flex {
  background: #fff !important;
  padding: 0 !important;
  margin-top: 14px !important;
}

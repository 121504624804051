@mixin navbar-vertical-style-1-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  navbar-vertical-style-1 {
    .navbar-top {
      @if ($is-dark) {
        background: #303030 !important;
      } @else {
      }
    }

    .navbar-scroll-container {
      @if ($is-dark) {
        background: #424242 !important;
      } @else {
      }

      .user {
        @if ($is-dark) {
          background: #303030 !important;
        } @else {
        }
      }

      .navbar-content {
        fuse-navigation {
          .nav-link.active {
            background-color: #6BC946 !important;
          }
        }
      }
    }
  }
}

/* You can add global styles to this file, and also import other style files */
@import './@fuse/scss/core';

// Import app.theme.scss
@import './app/app.theme';

// Import custom global theme
@import './assets/styles/fonts';

// Import Material Design Icons
@import '../node_modules/@mdi/font/css/materialdesignicons.min.css';


// -------------------------- Define Global Styles --------------------------
* {
  font-family: 'SourceSansPro' !important;
}

body {
  font-size: 10px;
}

p {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2em;
  margin: 0;
}
$breakpoint-mobile: 574px;
// Global Dialog Style
.cdk-overlay-container {
  font-size: 1.4rem;
}
.fc-widget-content {
  .fc-scroller {
    height: auto !important;
    display: block !important;
    overflow: hidden !important;
  }
}
.unavailable {
  border-color: #000000 !important;
  background: repeating-linear-gradient(
    45deg,
    #929292,
    #929292 10px,
    #c0c0c0 10px,
    #c0c0c0 20px
  ) !important;
}
.fc-row {
  height: 85px !important;
}
.fc-widget-header {
  height: 20px !important;
}
a.fc-event {
  cursor: pointer !important;
}
a.fc-event-draggable {
  cursor: pointer !important;
}
.fc-button {
  background-color: #039be5 !important;
}
.fc-day-number {
  color: #039be5 !important;
}
.fc-widget-header {
  color: #039be5 !important;
}

.fee-range {
  .mat-form-field-flex {
    padding: 0 0.75em 0 0.1em !important;
  }
}

.full-calendar-form-dialog {
  .mat-dialog-container {
    padding: 0 !important;
  }
}
.task-schedule-form-dialog {
  .mat-dialog-container {
    padding: 0 !important;
  }
}
.fee-form-dialog {
  .mat-dialog-container {
    padding: 0 !important;
  }
}
.mattooltip {
  white-space: pre-line !important;
}

@mixin salespro-customer-number-stepper-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  salespro-customer-number-stepper {
    .number-stepper {
      .value {
        @if ($is-dark) {
        } @else {
          color: #3C3C3C;
        }
      }
    }
  }
}

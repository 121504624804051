@font-face {
    src: url("~assets/fonts/SourceSansPro-ExtraLight.ttf");
    font-family: "SourceSansPro";
    font-weight: 200;
}

@font-face {
    src: url("~assets/fonts/SourceSansPro-Light.ttf");
    font-family: "SourceSansPro";
    font-weight: 300;
}

@font-face {
    src: url("~assets/fonts/SourceSansPro-Regular.ttf");
    font-family: "SourceSansPro";
    font-weight: 400;
}

@font-face {
    src: url("~assets/fonts/SourceSansPro-Semibold.ttf");
    font-family: "SourceSansPro";
    font-weight: 600;
}

@font-face {
    src: url("~assets/fonts/SourceSansPro-Bold.ttf");
    font-family: "SourceSansPro";
    font-weight: 700;
}

@font-face {
    src: url("~assets/fonts/SourceSansPro-Black.ttf");
    font-family: "SourceSansPro";
    font-weight: 900;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:100,300,400,500,700');

@mixin salespro-customer-add-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  salespro-customer-add {
    .customer-add {
      @if ($is-dark) {
      } @else {
        background: #F5F5F5;
      }

      > .header {
        .mat-form-field {
          .mat-form-field-wrapper {
            .mat-form-field-outline > div {
              @if ($is-dark) {
                background: map-get($background, card);
              } @else {
                background: #FFFFFF;
              }
            }
          }
        }
      }

      .body {
        @if ($is-dark) {
          background: map-get($background, card);
        } @else {
          background: #FFFFFF;
        }

        .steps {
          .step {
            &.active {
              .step-number {
                @if ($is-dark) {
                  background: #6BC946;
                } @else {
                  background: #3C4252;
                }
              }

              .step-name {
                @if ($is-dark) {
                  color: rgba(255, 255, 255, 1);
                } @else {
                  color: #3C4252;
                }
              }
            }

            .step-number {
              @if ($is-dark) {
                background: rgba(107, 201, 70, .6);
              } @else {
                background: #A1A1A1;
              }
            }

            .step-name {
              @if ($is-dark) {
                color: rgba(255, 255, 255, .5);
              } @else {
                color: #A1A1A1;
              }
            }
          }
        }
      }
    }
  }
}

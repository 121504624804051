@use '@angular/material' as mat;
@mixin salespro-auth-login-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);
  $is-dark: map-get($theme, is-dark);

  salespro-auth-login {
    .login {
      .login-intro {
        color: white;
      }

      .login-form-wrapper {
        @if ($is-dark) {
          background: mat.get-color-from-palette($fuse-navy, 600);
        } @else {
          background: map-get($background, card);
        }

        .login-form {
          .separator {
            color: map-get($foreground, divider);

            .text {
              &:before,
              &:after {
                border-top-color: map-get($foreground, divider);
              }
            }
          }
        }
      }
    }
  }
}

@mixin salespro-customer-plan-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  salespro-customer-plan {
    @if ($is-dark) {
    } @else {
      background: #F5F5F5;
    }

    .plan-list {
      .plan-item {
        @if ($is-dark) {
          background: map-get($background, card);
        } @else {
          background: #FFFFFF;
        }

        .title {
          @if ($is-dark) {
            background: map-get($background, app-bar);
          } @else {
            background: #3C4252;
          }
        }

        .price {
          .currency {
            @if ($is-dark) {
            } @else {
              color: #828282;
            }
          }

          .value {
            @if ($is-dark) {
            } @else {
              color: #3C4252;
            }
          }

          .period {
            @if ($is-dark) {
            } @else {
              color: #9B9B9B;
            }
          }
        }

        .price-rmr {
          @if ($is-dark) {
          } @else {
            color: #3C4252;
          }
        }

        .terms {
          @if ($is-dark) {
          } @else {
            color: #3C4252;
          }
        }
      }
    }
  }
}

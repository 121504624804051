@mixin salespro-customer-complete-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  salespro-customer-complete {
    @if ($is-dark) {
    } @else {
      background: #F5F5F5;
    }

    .complete {
      > .content {
        @if ($is-dark) {
          background: map-get($background, card);
        } @else {
          background: #FFFFFF;
        }

        .logo {
          @if ($is-dark) {
            content: url("/assets/images/logos/homepro-white.png");
          } @else {
            content: url("/assets/images/logos/homepro-black.png");
          }
        }

        .description {
          @if ($is-dark) {
          } @else {
            color: #3F3F3F;
          }
        }
      }
    }
  }
}

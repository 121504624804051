@mixin salespro-docusign-prefill-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  salespro-docusign-prefill {
    .customer-add {
      @if ($is-dark) {
      } @else {
        background: #f5f5f5;
      }

      > .header {
        .mat-form-field {
          .mat-form-field-wrapper {
            .mat-form-field-outline > div {
              @if ($is-dark) {
                background: map-get($background, card);
              } @else {
                background: #ffffff;
              }
            }
          }
        }
      }

      .body {
        @if ($is-dark) {
          background: map-get($background, card);
        } @else {
          background: #ffffff;
        }

        .steps {
          .step {
            &.active {
              .step-number {
                @if ($is-dark) {
                  background: #6bc946;
                } @else {
                  background: #3c4252;
                }
              }

              .step-name {
                @if ($is-dark) {
                  color: rgba(255, 255, 255, 1);
                } @else {
                  color: #3c4252;
                }
              }
            }

            .step-number {
              @if ($is-dark) {
                background: rgba(107, 201, 70, 0.6);
              } @else {
                background: #a1a1a1;
              }
            }

            .step-name {
              @if ($is-dark) {
                color: rgba(255, 255, 255, 0.5);
              } @else {
                color: #a1a1a1;
              }
            }
          }
        }
      }
    }
  }
}

@mixin salespro-customer-equipment-review-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  salespro-customer-equipment-review {
    @if ($is-dark) {
    } @else {
      background: #F5F5F5;
    }

    .equipment-review {
      .body {
        @if ($is-dark) {
          background: map-get($background, card);
        } @else {
          background: #FFFFFF;
        }

        .content {
          .package-plan, .equipment-list {
            .title {
              @if ($is-dark) {
              } @else {
                color: #3F3F3F;
              }
            }

            .items {
              .item {
                .name {
                  @if ($is-dark) {
                  } @else {
                    color: #6E6E6E;
                  }
                }

                .quantity {
                  @if ($is-dark) {
                  } @else {
                    color: #6E6E6E;
                  }
                }
              }
            }
          }

          .add-on {
            .title {
              @if ($is-dark) {
              } @else {
                color: #3F3F3F;
              }
            }

            .items {
              .item {
                .name {
                  @if ($is-dark) {
                  } @else {
                    color: #6E6E6E;
                  }
                }

                .include {
                  @if ($is-dark) {
                  } @else {
                    color: #6E6E6E;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

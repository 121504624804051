@mixin salespro-customer-equipment-select-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  salespro-customer-equipment-select {
    .equipment-select {
      @if ($is-dark) {
      } @else {
        background: #F5F5F5;
      }

      .equipments-list {
        .content, .add-on {
          .header {
            .title {
              @if ($is-dark) {
              } @else {
                color: #3F3F3F;
              }
            }
          }

          .body {
            .equipment-item {
              .info {
                @if ($is-dark) {
                } @else {
                  color: #656565;
                }
              }

              .price {
                @if ($is-dark) {
                } @else {
                  color: #656565;
                }
              }
            }
          }
        }
      }
    }
  }
}

@mixin salespro-lend-foundry-info-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $is-dark: map-get($theme, is-dark);
  
    salespro-lendfoundry-info {
      @if ($is-dark) {
      } @else {
        background: #F5F5F5;
      }
  
      .complete {
        > .content {
          @if ($is-dark) {
            background: map-get($background, card);
          } @else {
            background: #FFFFFF;
          }
  
        }
      }
    }
  }
  
@mixin salespro-confirm-old-add-flow-modal-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .old-add-flow {
    .mat-dialog-container {
      padding: 0 !important;
      height: 339px;
      width: 566px;

      .mat-toolbar {
        flex: 1 0 auto;
        min-height: initial;
      }

      .toolbar-bottom {
        height: auto;
      }
    }
    .dialog-content-wrapper {
      max-height: 85vh;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .header {
        background-color: #6bc946;

        .header-title {
          height: 76px;

          .dialog-title {
            color: #ffffff;
            font-size: 2rem;
            font-weight: 400;
          }

          .close-button {
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
      .content{
        overflow-y: hidden;
        .error-msg{
          font-size: 1.7rem;
          font-weight: 600;
        }
        .save-button {
          //padding: 30px 40%;
          @if ($is-dark) {
          } @else {
            color: #757575;
          }
        }
      }
      
    }
  }
}

@mixin salespro-shared-site-address-auto-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .site-auto-form-dialog {
    .mat-dialog-container {
      padding: 0 !important;
      height: 300px;
      width: 561px;

      .mat-toolbar {
        flex: 1 0 auto;
        min-height: initial;
      }

      .toolbar-bottom {
        height: auto;
      }
    }
    .dialog-content-wrapper {
      max-height: 85vh;
      display: flex;
      flex-direction: column;
      .header {
        background-color: #6bc946;

        .header-title {
          height: 76px;

          .dialog-title {
            color: #ffffff;
            font-size: 2rem;
            font-weight: 400;
          }

          .close-button {
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
      .input-search {
        width: 100%;
        height: 46px;
      }
      .content {
        display: flex;
        flex-direction: column;
        padding: 4rem 3rem 0;
      }
      .search {
        border: 1px solid #e0e0e0;
      }
      .icon-search {
        margin-left: 10px;
      }
      .leadtype {
        width: 460px;
        @include media-breakpoint('lt-md') {
          width: 330px !important;
        }
        @include media-breakpoint('lt-sm') {
          width: 200px !important;
        }
      }

      .footer {
      }
      .notify-text {
        margin-top: 7px;

        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
